/* Container styling */
.stars-content-container {
  padding-top: 100px;
  padding-bottom: 200px;
}

.stars-page-container {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  /* min-height: 100vh; */
  padding-top: 80px;
  padding-bottom: 200px;
}

.stars-page-title {
  color: #fff; /* Accent color for the title */
  text-align: center;
  margin-left: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 28px;
  font-weight: bold;
}

.starred-tracks-list-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto; /* Center the list */
}

.starred-track-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #000; /* Dark gray for card background */
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.starred-track-item:hover {
  transform: translateY(-3px); /* Slight lift effect */
  box-shadow: 0 4px 15px rgba(255, 83, 73, 0.3); /* Accent color shadow */
}

@media (max-width: 480px) {
  .starred-track-item {
    flex-direction: column; /* Display single column on small devices */
  }
  .track-cover-image {
    margin: 0 auto;
    width: 98% !important;
    height: 240px !important;
  }
  .track-title {
    margin-top: 10px !important;
  }
}

.track-cover-image {
  width: 20%;
  height: 90%;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
  border: 2px solid #ff5349; /* Accent color border around the cover */
}

.track-details-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.track-title {
  margin: 0;
  font-size: 18px;
  color: #ff5349; /* Accent color for track title */
}

.track-detail {
  margin: 2px 0;
  font-size: 14px;
  color: #fff; /* White text */
}

.no-stars-message {
  text-align: center;
  color: #ff5349;
  margin-top: 5px;
  margin-left: 10px;
  font-style: italic;
}

.loading-message {
  text-align: center;
  color: #ff5349; /* Accent color for loading message */
  margin-top: 20px;
}

.collaboration-message {
  font-weight: bold;
  color: #ff5349;
}

