/* Container */

.messages-content-container {
  padding-top: 60px;
}

.messages-container {
    color: #fff; /* White text */
    padding: 20px;
    border-radius: 0px;
    padding-bottom: 200px;
  }

  .messages-header {
    margin-bottom: 0;
    text-align: center;
    margin-left: 10px;
    color: #fff;
  }
  
  /* Section Headings */
  .messages-container h2 {
    color: #fff; /* Red-orange accent color */
    text-align: center;
    margin-bottom: 15px;
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
    font-size: 24px;
  }
  
  /* Message and Conversation Styles */
  .message,
  .conversation {
    background-color: #000; /* Slightly lighter black for contrast */
    padding: 15px;
    border-radius: 10px;
    margin: 10px 0;
  }
  
  .message p,
  .conversation p {
    margin: 0;
    color: #fff;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
  }

  .conversation p:hover {
    color: #ff5349;
    transition: 0.3s;
  }
  
  /* Status Text */
  .status-accepted {
    color: #0f0; /* Green for accepted */
  }
  
  .status-denied {
    color: #f00; /* Red for denied */
  }
  
  /* Button Styles */
  .button-container {
    margin-top: 10px;
  }
  
  .button-container button {
    background-color: #ff5349; /* Red-orange accent color */
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .button-container button:hover {
    background-color: #ff796b; /* Lighter shade on hover */
  }
  
  .button-container button:focus {
    outline: none;
  }
  
  /* No messages or conversations */
  .no-messages,
  .no-conversations {
    text-align: center;
    color: #ff5349; /* Grey to indicate emptiness */
    font-style: italic;
    padding: 10px;
  }
  