/* src/styles/AudioPlayer.css */
.audio-player {
  position: fixed;
  bottom: 73px; /* Adjust based on your bottom navbar height */
  left: 0;
  right: 0;
  background-color: #111;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 100;
  transition: height 0.3s ease, bottom 0.3s ease; /* Add transition for sliding */
  overflow: hidden; /* Hide overflow when sliding */
  height: 73px; /* Minimized height */
}
  
.audio-player.expanded {
  height: 100%; /* Full height when expanded */
  bottom: 0;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  z-index: 99999;
  transition: height 0.3s ease, bottom 0.3s ease; /* Ensure transition is applied here too */
}

  

.audio-player-minimized {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.audio-player-expanded {
  margin-top: 100px;
  position: relative;
  transition: transform 0.3s ease; /* Add smooth sliding effect */
}
  
  .cover-photo {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  .track-info {
    flex-grow: 1;
    margin-left: 10px;
    text-align: left;
  }
  
  .track-title-audio-player {
    font-size: 14px;
    margin: 0;
  }
  
  .track-artist {
    font-size: 12px;
    color: #aaa;
    margin: 0;
  }
  
  .play-pause-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    display: flex;
    margin: 0 auto;
  }
  
  .large-cover-photo {
    width: 90%;
    max-width: 70%;
    height: auto;
    display: flex;
    margin: 0 auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Adds a subtle, soft box shadow */
    border-radius: 8px; /* Optional: add rounded corners for a smoother look */
    border: 1px solid #ff5349;
  }
  
  .expanded-track-info {
    text-align: center;
    margin: 10px 0;
  }
  
  .expanded-play-pause {
    font-size: 40px;
    display: flex;
    margin: 0 auto;
  }
  
  /* Styles for the minimize caret icon */
  .minimize-caret {
    position: absolute;
    top: -33px;
    left: 10px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 4px;
    transition: background 0.3s ease;
  }
  
  .minimize-caret:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  /* Progress bar styles */
  .progress-bar-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px; /* The 2px thickness you requested */
    background-color: #444; /* Unplayed portion color */
  }
  
  .progress-bar {
    height: 100%;
    background-color: #fff; /* Played portion color */
    width: 0%; /* Start with no progress */
    transition: width 0.1s linear; /* Smooth transition */
  }

  .progress-bar-container-expanded {
    position: relative;
    height: 2px; /* The 2px thickness you requested */
    background-color: #444; /* Unplayed portion color */
    width: 70%;
    margin: 0 auto;
  }

/* Expanded progress bar styles */
.progress-thumb {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    top: -4px; /* Center the thumb vertically on the progress bar */
    cursor: pointer;
    transform: translateX(-50%);
    z-index: 10; /* Ensure the thumb is above the progress bar */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5); /* Optional shadow for better visibility */
  }

.expanded-progress-bar-container {
    width: 90%; /* Adjust the width of the progress bar */
    height: 4px; /* Slightly thicker than the minimized bar */
    background-color: #444; /* Unplayed portion color */
    cursor: pointer;
    margin: 10px auto;
    position: relative;
    border-radius: 2px;
  }
  
  .expanded-progress-bar {
    height: 100%;
    background-color: #fff; /* Played portion color */
    width: 0%; /* Start with no progress */
    transition: width 0.1s linear; /* Smooth transition */
    border-radius: 2px;
  }
  
  /* Display the current and total time */
  .time-info {
    display: flex;
    justify-content: space-between;
    width: 70%; /* Matches the width of the progress bar */
    margin: 5px auto 0;
    font-size: 12px;
    color: #aaa;
  }
  
  .current-time {
    margin-left: 5px;
  }
  
  .duration-time {
    margin-right: 5px;
  }
  
  