/* Cancel Page Styles */

.cancel-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7; /* Light neutral background */
    color: #333; /* Darker text for readability */
    padding: 0 20px;
  }
  
  .cancel-container h1 {
    font-size: 3rem;
    color: #ff5349; /* Sync's red-orange accent color to indicate cancellation */
    margin-bottom: 20px;
    font-family: 'Helvetica Neue', sans-serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .cancel-container p {
    font-size: 1.5rem;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    max-width: 600px;
  }
  
  /* Add a subtle button-style link to direct back to a relevant page */
  .cancel-container a {
    text-decoration: none;
    color: #fff;
    background-color: #333; /* Neutral dark color for retrying */
    padding: 10px 30px;
    font-size: 1.2rem;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
  .cancel-container a:hover {
    background-color: #444;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .cancel-container h1 {
      font-size: 2.5rem;
    }
  
    .cancel-container p {
      font-size: 1.2rem;
    }
  }
  