/* src/styles/Home.css */
.home-container {
    padding-top: 60px;
    padding-bottom: 200px
  }
  
  .home-page-header {
    text-align: center;
    font-size: 28px;
    color: #fff;
  }
  .music-feed {
    display: flex;
    flex-wrap: wrap;
  }
  .empty-home-message {
    padding-top: 100px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    color: #ff5349;
  }
  .empty-home-go-to-search-btn {
    border-radius: 33px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    margin: 15px auto 0;
    padding: 20px;
    text-decoration: none;
    width: 33%;
    border: 1px solid;
  }

  .empty-home-go-to-search-btn:hover {
    background-color: #000;
  }
  .upload-item {
    width: 100%;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .upload-cover {
    width: 100%;
    height: auto;
  }
  
  .upload-details {
    width: 100%;
    margin-left: 10px;
    text-align: left;
  }
  
  .upload-details h3 {
    margin: 0;
  }
  
  .upload-details p {
    margin: 5px 0;
  }

  .no-home-uploads {
    color: black;
    padding-top: 100px;
  }

  .home-artist-upload {
    color: #aaa;
  }

  .home-genre-upload {
    color: #ff5349;
  }

  .likes-plays-section {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 5px;
  }
  
  .like-icon,
  .play-icon {
    /* margin-right: 5px; */
    color: #fff; /* Customize the color if needed */
  }
  
  .likes-count,
  .plays-count {
    display: flex;
    align-items: center;
    font-size: 0.9em;
  }
  
  