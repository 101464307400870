/* Success Page Styles */

.success-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7; /* Light background to keep it clean */
    color: #333; /* Dark text for better readability */
    padding: 0 20px;
  }
  
  .success-container h1 {
    font-size: 3rem;
    color: #4caf50; /* Green color to symbolize success */
    margin-bottom: 20px;
    font-family: 'Helvetica Neue', sans-serif;
    text-align: center;
    text-transform: uppercase; /* Make it bold and official */
    letter-spacing: 1px;
  }
  
  .success-container p {
    font-size: 1.5rem;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    max-width: 600px;
  }
  
  /* Add a subtle button-style link to direct back to the app */
  .success-container a {
    text-decoration: none;
    color: #fff;
    background-color: #ff5349; /* Sync's red-orange accent color */
    padding: 10px 30px;
    font-size: 1.2rem;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
  .success-container a:hover {
    background-color: #e0483e;
  }
  
  /* Responsive for smaller screens */
  @media (max-width: 768px) {
    .success-container h1 {
      font-size: 2.5rem;
    }
  
    .success-container p {
      font-size: 1.2rem;
    }
  }
  