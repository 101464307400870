.privacy-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    color: #fff;
  }
  
  .privacy-heading {
    text-align: center;
    color: #ff5349;
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .privacy-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .privacy-section {
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 1.5rem;
    color: #ff5349;
    margin-bottom: 10px;
  }
  
  .section-paragraph {
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
  }
  