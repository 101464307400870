.bottom-navbar {
  position: fixed;
  bottom: -1px;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  z-index: 1000; /* Keeps the navbar on top */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
}

.bottom-navbar a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  transition: color 0.3s ease, transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-navbar a:hover {
  color: #ddd; /* Slightly lighter on hover */
  /* transform: translateY(-3px); */
}

.bottom-navbar a.active {
  color: #ff5349; /* Red-orange for the active tab */
}

/* .bottom-navbar a:active {
  transform: scale(0.95);
} */

.bottom-navbar .nav-label {
  margin-top: 5px; /* Space between the icon and the text */
  font-size: 10px; /* Adjusted font size for labels */
}

/* Adjust icon size */
.bottom-navbar svg {
  font-size: 1.5rem; /* Adjust icon size */
}

/* Responsive behavior */
@media (max-width: 600px) {
  .bottom-navbar a {
    font-size: 16px;
    padding: 8px 12px;
  }

  .bottom-navbar svg {
    font-size: 1.3rem; /* Slightly smaller icons on smaller screens */
  }
}

@media (max-width: 321px) {
  .bottom-navbar a {
    font-size: 16px;
    padding: 8px 10px;
  }

  .bottom-navbar svg {
    font-size: 1.3rem; /* Slightly smaller icons on smaller screens */
  }
}
