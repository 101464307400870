/* Profile Setup Container Styles */
.profile-setup-container {
  margin: 50px auto;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  height: 50vh;
  display: flex;
  align-items: center;
}

/* Form Heading */
h2 {
  text-align: center;
  color: #ff5349; /* Use the same accent color as Profile */
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px; /* Adjust margin for consistency */
}

label {
  font-size: 16px;
  color: #fff; /* Ensure label text is white */
  font-weight: bold;
}

/* Form Inputs, Selects, and Textareas */
input[type="text"],
input[type="file"],
textarea,
select {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #000; /* Match the background color */
  color: #fff;
  font-size: 1rem;
  outline: none;
  width: 100%; /* Ensure full-width */
  box-sizing: border-box;
}

input:focus, select:focus, textarea:focus {
  border-color: #ff5349; /* Match the focus color */
}

textarea {
  resize: none;
  height: 100px;
}

/* Button Styles */
button.profile-setup-button {
  background-color: #ff5349; /* Match the accent color */
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

button.profile-setup-button:hover {
  background-color: #008000; /* Use green hover effect from Profile */
  transform: translateY(-2px); /* Add hover effect for consistency */
}

/* Multi-select List Styles */
select[multiple] {
  height: auto; /* Adjust height */
  min-height: 120px;
  padding: 8px;
}

option {
  padding: 8px;
  background-color: #000; /* Match background color */
  color: #fff;
}

option:hover {
  background-color: #ff5349;
}

/* Selected Genres Section */
.selected-genres {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.profile-setup-form {
  width: 100%;
  padding: 20px;
}

/* Styles for Genres Selection */
.genres-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.genre-button {
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.genre-button.selected {
  background-color: #ff5349;
}

.genre-button:hover {
  background-color: #ff5349;
}

.selected-genre {
  padding: 10px 15px;
  background-color: #ff5349;
  border-radius: 20px;
  position: relative;
  color: #fff;
  font-size: 14px;
}

.remove-genre {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #f00;
  border-radius: 50%;
  padding: 0 6px;
}

/* Profile Picture Upload */
input[type="file"] {
  background-color: #000; /* Slightly lighter for contrast */
}

/* Loader Styles */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff5349; /* Sync's red-orange color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.profile-setup-next {
  color: #fff;
  background: #ff5349;
}

.profile-setup-back {
  color: #fff;
  background: #000;
  border: 1px solid #ff5349;
  margin-right: 12px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .profile-setup-container {
  }

  h2 {
    font-size: 2rem;
  }

  button.profile-setup-button {
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .profile-setup-container {
    padding: 5px;
  }

  h2 {
    font-size: 1.5rem;
  }

  button.profile-setup-button {
    padding: 8px 10px;
  }
}
