/* src/styles/StaticPages.css */
.static-page-container {
    padding: 20px;
    color: #000;
    text-align: left;
  }
  
  h1 {
    color: #ff5349;
    text-align: left;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  p {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    font-size: 1.1rem;
    /* margin-bottom: 10px; */
  }
  