/* Container */
.chat-thread {
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;
    padding-bottom: 200px;
    padding-top: 100px;
    justify-content: center;
    margin: 0 auto;
  }
  
  /* Header */
  .chat-thread h2 {
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
    font-size: 24px;
  }
  
  .close-button {
    background-color: transparent;
    color: #ff5349;
    border: none;
    cursor: pointer;
    font-weight: bold;
    align-self: flex-start;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .close-button:hover {
    text-decoration: underline;
  }
  
  /* Messages Container */
  .messages-container {
    background-color: #1a1a1a;
    padding: 10px;
    border-radius: 10px;
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: 10px;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
  }
  
  /* Message Styles */
  .message {
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
    max-width: 60%;
    line-height: 1.5;
    word-break: break-word;
    display: inline-block;
    position: relative;
  }
  
  /* Sent Messages */
  .message.sent {
    background-color: #ff5349; /* Red-orange for sent messages */
    color: #fff;
    align-self: flex-end; /* Align to the right */
    text-align: right;
    border-top-right-radius: 0;
  }
  
  /* Received Messages */
  .message.received {
    background-color: #333; /* Dark gray for received messages */
    color: #fff;
    align-self: flex-start; /* Align to the left */
    text-align: left;
    border-top-left-radius: 0;
  }
  
  /* Message Input */
  .message-input-container {
    display: flex;
    margin-top: 10px;
  }
  
  .message-input-container input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 5px 0 0 5px;
    outline: none;
    background-color: #333;
    color: #fff;
  }
  
  .message-input-container input::placeholder {
    color: #bbb;
  }
  
  .message-input-container button {
    background-color: #ff5349;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .message-input-container button:hover {
    background-color: #ff796b;
  }
  
  .message-input-container button:focus {
    outline: none;
  }
  