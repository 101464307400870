/* Top Bar Styles */
.top-bar {
  background-color: #000;
  color: #fff;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

/* Ensure the logo link behaves like a logo */
.logo-link {
  text-decoration: none; /* Remove underline */
  display: flex;
  flex-direction: row;
}

.sync-logo-top-bar {
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.logo {
  font-size: 2rem;
  color: #fff;
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Centered "Go Premium" Button */
.centered-go-premium {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.go-premium-button {
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #ff5349;
}


.crown-icon {
  margin-right: 8px; /* Add some space between the icon and text */
  font-size: 1.2rem;
}

.go-premium-button:hover {
  background-color: #ff5349;
}

/* Navigation Links */
.top-bar-nav {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.nav-link {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.nav-link:hover {
  color: #ff5349;
}

.active-link {
  font-weight: bold;
  border-bottom: 2px solid #ff5349;
}

.top-bar-icon {
  height: 25px;
  height: 25px;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .top-bar-nav {
    gap: 10px;
  }

  .nav-link {
    font-size: 0.9rem;
  }

  .logo {
    font-size: 1.8rem;
  }
}

@media (max-width: 500px) {
  .top-bar-nav {
    flex-direction: row;
    gap: 10px;
  }

  .nav-link {
    font-size: 0.9rem;
  }

  .logo {
    font-size: 1.5rem;
  }
}
