/* Search Page Styles - Updated for Star Icon */

/* General Styling */
body {
  background-color: #fff;
  color: #fff;
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

.sticky-search-bar {
  position: sticky;
  top: 70px;
  z-index: 1000; /* Ensures it stays on top of other elements */
}

/* Container for the input and icon */
.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-icon {
  position: absolute;
  left: 10px; /* Adjust the distance from the left as needed */
  color: #aaa; /* Set the color for the magnifying glass */
  font-size: 18px; /* Adjust the size of the icon */
  pointer-events: none; /* Prevent the icon from blocking clicks on the input */
}

.clear-icon {
  position: absolute;
  right: 10px; /* Adjust the distance from the right */
  color: #aaa;
  font-size: 18px;
  cursor: pointer;
}

.clear-icon:hover {
  color: white;
  transition: 0.2s;
}

.search-input {
  width: 100%;
  padding: 12px 12px 12px 35px; /* Adjust the left padding to make room for the icon */
  font-size: 1rem;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  box-sizing: border-box;
}

.search-input:focus {
  border-color: #ff5349;
  outline: none;
}


.search-container {
  margin: 0px auto;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  padding-bottom: 200px;
  padding-top: 60px;
}

h2 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 5px;
  text-align: center;
}

.no-results {
  font-size: 14px;
  font-style: italic;
  color: #ff5349;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.no-results-btn {
  padding: 20px;
  background-color: #ff5349;
  color: #fff;
  margin-top: 15px;
  text-decoration: none;
  font-style: normal;
  border-radius: 3px;
  font-size: 16px;
}

/* Input Styles */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  box-sizing: border-box;
}

.search-input:focus {
  border-color: #ff5349;
}

.search-input-label {
  text-align: left;
  font-size: 1.2rem;
  color: #000;
  font-weight: bold;
}

#track-search {
  padding-left: 33px;
}

/* Genres List */
.genres-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.genre-button {
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.genre-button.selected {
  background-color: #ff5349;
}

.genre-button:hover {
  background-color: #ff5349;
}

/* Search Button */
.search-button {
  background-color: #ff5349;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  width: 100%;
}

.search-button:hover {
  background-color: #008000;
}

/* Search Results Styling */
.search-results {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.result-item {
  background-color: #000;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 575px;
  box-sizing: border-box;
}

.result-item h3 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: left;
}

.result-item p {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: left;
}

/* Cover Photo */
.result-item img {
  flex-grow: 1;
  max-height: 180px;
  height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid #fff;
  margin-bottom: 15px;
}

/* Star Icon and Count */
.star-section {
  display: flex;
  align-items: center;
  gap: 5px;
}

.star-icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s;
}

.star-icon path:hover {
  color: #ff5349;
  transition: 0.3s;
}

.star-count {
  font-size: 14px;
  color: #fff;
}

.like-icon {
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
}

.like-icon path:hover {
  color: #ff5349;
  transition: 0.3s;
}

.like-count {
  color: #fff;
  font-size: 14px;
}

.play-count {
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
}


/* Audio Player */
audio {
  width: 100%;
  background: none;
  min-height: 40px;
}

/* Responsive Layout for Smaller Screens */
@media (max-width: 1024px) {
  .search-results {
    grid-template-columns: 1fr 1fr; /* 2 columns on tablets */
  }
}

@media (max-width: 768px) {
  .search-results {
    grid-template-columns: 1fr; /* 1 column on mobile */
  }
  .result-item {
    height: 560px; /* Adjust height for smaller screens */
  }

  .result-item img {
    width: 100%; /* Ensure the image fits the container */
  }
}

@media (max-width: 480px) {
  .result-item {
    height: 560px; /* Adjust height for even smaller screens */
  }
}
