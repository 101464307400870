/* Global Styles */
body {
  background-color: #fff;
  color: #fff;
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

.profile-container {
  margin: 0px auto;
  padding: 10px;
  padding-bottom: 200px;
  /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); */
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 90%; /* Or set a fixed width like 500px */
  margin: 0 auto; /* Center the content */
}


h1 {
  text-align: center;
  color: #ff5349;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.profile-picture img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 3px solid #ff5349;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
}

/* Centering and Styling the Form */
.profile-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group label {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

/* Ensure all form elements are consistent */
input, select, textarea {
  padding: 12px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  outline: none;
  width: 100%; /* Ensure full-width for all inputs, selects, and textareas */
  box-sizing: border-box; /* Ensure the padding is included in the width */
}

input:focus, select:focus, textarea:focus {
  border-color: #ff5349;
}

textarea {
  resize: none;
  height: 100px;
}

/* Genres List */
.genres-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.genre-button {
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.genre-button.selected {
  background-color: #ff5349;
}

.genre-button:hover {
  background-color: #ff5349;
}

.selected-genres {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected-genre {
  padding: 10px 15px;
  background-color: #ff5349;
  border-radius: 20px;
  position: relative;
}

.remove-genre {
  background: none;
  border: none;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #f00;
  border-radius: 50%;
  padding: 0 6px;
}

/* Discography */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabs button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  width: 100%;
  margin: 7px;
}

.tabs button.active-tab {
  background-color: #ff5349;
}

.tabs button:hover {
  background-color: #ff5349;
}

.discography-container {
  padding-bottom: 200px;
  border-radius: 10px;
}

.upload-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.upload-item {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: fit-content;
  box-sizing: border-box;
  align-items: center;
  position: relative;
}

.upload-item-container {
  display: flex;
  flex-direction: row;
  width: 45%;
}

.three-dots-menu {
  position: absolute;
  right: 20px; /* Adjust as needed to ensure it sits on the far right */
  top: 10px; /* Adjust to ensure it aligns vertically with the content */
  cursor: pointer;
  font-size: 1.2rem;
  color: #fff;
  transition: transform 0.2s;
}

.three-dots-menu:hover {
  transform: scale(1.2); /* Slightly enlarges the icon when hovered */
  color: #ff5349; /* Changes color to match the accent */
}

/* Dropdown options menu styles */
.options-menu {
  position: absolute;
  top: 35px; /* Positions the menu just below the three dots */
  right: 15px; /* Aligns the menu with the three dots */
  background-color: #000;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
  z-index: 10; /* Ensures the menu appears above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Adds a subtle shadow for depth */
}

.options-menu button {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  text-align: left;
  width: 100%; /* Ensures the button takes up the full width */
  transition: background-color 0.3s;
}

.options-menu button:hover {
  background-color: #ff5349; /* Matches your accent color */
  color: #000; /* Changes text color when hovered */
  border-radius: 3px;
}

@media (max-width: 768px) {
  .upload-item {
    flex-direction: column;
  }
  .upload-item-container {
    width: 100%;
  }
}

.upload-item p {
  font-size: 1.2rem;
}

audio {
  width: 100%;
  background: none;
  height: 20px;
}

/* Buttons */
button {
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}y

button:hover {
  transform: translateY(-2px);
}

.update-profile-button {
  background-color: #ff5349;
  color: #fff;
}

.update-profile-button:hover {
  background-color: #008000;
}

.logout-button {
  width: 40%;
  background-color: #000;
  color: #fff;
  margin: 40px 40px 40px 0;
}

.logout-button:hover {
  color: #f00;
}

.discography-genre {
  color:#aaa;
}

.discography-card-container {
  display: flex;
  flex-direction: column;
}

.discography-upload-details {
  width: 180px;
  margin-left: 10px;
}

/* Discography Cover Photo Styles */
.upload-item img {
  /* flex-grow: 1;  */
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.upload-item h3, .upload-item p {
  font-weight: normal;
  font-size: 14px;
  margin: 0;
}

.upload-item audio {
  min-height: 40px;
  margin-top: 10px;
  margin-bottom: 3px;
}

.no-uploads-msg {
  display: flex;
  color: #000;
  justify-content: center;
  margin-left: 20px;
  color: #ff5349;
}

/* Profile banner styling */
.profile-banner {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  margin-bottom: 20px;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area */
}

.banner-bio {
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 666px;
}

.artist-name {
  position: absolute;
  bottom: 0px; /* Position the text 10px from the bottom */
  left: 15px;   /* Position the text 15px from the left */
  color: #ff5349;  /* Set text color to white */
  font-size: 4rem; /* Adjust the font size */
  font-weight: bold; /* Make the text bold */
}

.bio-container {
  display: flex;
  flex-direction: column;
}

.bio-content {
  justify-content: ceewnter;
}

@media (max-width: 480px) {
  .artist-name {
    font-size: 2.5rem; /* Adjust font size for smaller screens */
  }
}

.update-profile-header {
  font-size: 24px;
  color: #ff5349;
  text-align: left;
}

.discography-header {
  font-size: 28px;
  margin-left: 20px;
  text-align: center;
  margin-bottom: 5px;
  color: #fff;
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .discography-card-container {
    grid-template-columns: 1fr 1fr; /* Display 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  .discography-card-container {
    grid-template-columns: 1fr; /* Display single column on small devices */
  }
}
