.upload-container {
  margin: 0px auto;
  padding: 10px;
  background-color: #000;
  border-radius: 10px;
  /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); */
  text-align: center;
  padding-bottom: 200px;
  padding-top: 60px;
}

h2 {
  color: #ff5349;
  font-size: 2rem;
  margin-bottom: 20px;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the form content */
}

.upload-form input {
  width: 100%; /* Define the width of the input */
  margin: 0 auto 20px auto; /* Center the input */
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: 1px solid #444;
  border-radius: 5px;
}

.upload-button {
  background-color: #ff5349;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-bottom: 30px;
}

.upload-button:hover {
  background-color: #008000;
}

.form-group-upload {
  width: 100%;
  margin: 0 auto 20px auto;
}

.upload-label {
  color:#fff;
  text-align: left;
  display: flex;
}

#file-upload {
  margin-bottom: 5px;
}

#title-upload {
  margin: 0;
}

/* Loader Styles */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff5349; /* Sync's red-orange color for accent */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
